'use client'
import { CalendarIcon, DashboardIcon, Divider, TalentPoolIcon } from '~/scalis-components/core'
import { JobRequisitions } from './components'
import { Aside } from '../aside'
import { Routes } from '~/src/app/_constants/routes'
import { usePathname } from 'next/navigation'

enum CompanyRoutes {
  Home = '/company/home/active',
  Calendar = '/company/calendar',
  // Analytics = "/company/home/active",
}

export const DefaultSidebar = () => {
  const pathname = usePathname()
  const isActive = (path: string) => pathname?.includes(path)

  return (
    <Aside.Container
      collapsible
      collapsed
      persistCollapsed
    >
      {({ showContent, expand }) => {
        return (
          <>
            <Aside.List>
              <Aside.Item
                label='Dashboard'
                href={CompanyRoutes.Home}
                icon={DashboardIcon}
                active={isActive(CompanyRoutes.Home)}
                showContent={showContent}
              />
              {
                <Aside.Item
                  label='Calendar'
                  href='/company/calendar'
                  icon={CalendarIcon}
                  active={isActive(CompanyRoutes.Calendar)}
                  showContent={showContent}
                />
              }
              <Aside.Item
                label='Talent Pool'
                href={Routes.company.talent_pool}
                icon={TalentPoolIcon}
                active={isActive(Routes.company.talent_pool)}
                showContent={showContent}
              />
              {/* <Aside.Item
                label="Analytics"
                href="/company/home/active"
                icon={AnalyticsIcon}
                showContent={showContent}
              /> */}
            </Aside.List>
            <Divider />
            <div className='overflow-y-auto'>
              <JobRequisitions
                showContent={showContent}
                expand={expand}
              />
            </div>
          </>
        )
      }}
    </Aside.Container>
  )
}
