import { tv } from 'tailwind-variants'

export const buttonIcon = tv({
  base: 'rounded text-icon-neutral-20 disabled:text-neutral-disabled',
  variants: {
    size: {
      small: 'h-6 w-6',
      medium: 'h-8 w-8',
    },
    variant: {
      default: 'hover:enabled:bg-neutral-10 active:enabled:bg-neutral-30',
      grid: 'hover:enabled:bg-neutral-30 active:enabled:bg-neutral-40',
    },
  },
  defaultVariants: {
    variant: 'default',
  },
})
